#root {
  background-color: papayawhip;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  width: 65%;
  margin: 5% 10%;
}

.ifields {
  height: 40px;
  width: 100%;
  max-width: 100%;
}

.card-box {
  max-width: 450px;
  min-width: 406px;
  width: 425px;
}

.result-box {
  max-width: 450px;
  min-width: 406px;
  width: 425px;
}

#total {
  color: #00103a;
  font-size: 32px;
  font-weight: bold;
}

.token-field {
  word-wrap: break-word;
}

.smallWidth {
  max-width: 23%;
}

.button-spaced {
  padding: 10px 0;
}

.hidden {
  display: none;
  visibility: hidden;
}

.scrollable {
  scrollbar-width: thin;
  overflow-x: auto;
  overflow-y: auto;
  height: 300px;
}